import React from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import checkmarkWithCicle from 'static/svg/checkmark_with_circle.svg'
import { Flex, Box } from './Layout'
import { Text, H3, H5 } from './Typography'
import AgenciesCounter from './AgenciesCounter'
import Button from './Button'
import ExternalLink from './Link'
import LocalImage from './LocalImage'
import { COLOR_CONSTANTS, radius } from '../theme'

const StyledButtonPrimary = styled(Button.Primary)`
  border-radius: ${radius.xl};
  border: 1px solid ${colors.primary};
  &:hover {
    p {
      color: ${colors.primary};
    }
    background-color: ${colors.white};
  }
`

const FEATURE_ITEMS = ['Get started free', 'Setup in minutes', 'No credit card required']

const GetStartedComponent = () => {
  return (
    <Box maxWidth="1080px" width="100%" bg={COLOR_CONSTANTS.WHITE} pt="xl" pb="l" px={{ mobile: 'm', tablet: 'l' }}>
      <Flex flexDirection="column" alignItems="center" maxWidth="750px" mx="auto">
        <H3 textAlign="center">Grow with the #1 marketing agency network and top destination for businesses to hire</H3>
        <H5 mt="m" mb="l" textAlign="center" fontWeight="normal">
          Sign up for Agency Vista, and see why over <AgenciesCounter as="span" color="primary" fontWeight="bold" />{' '}
          <Text as="span" color="primary" fontWeight="bold">
            marketing agencies
          </Text>{' '}
          trust us to grow their online presence and foster credible relationships with businesses. We’re free forever,
          and you can upgrade, downgrade, or cancel any time.
        </H5>

        <StyledButtonPrimary as={ExternalLink} href="/signup">
          <Text fontWeight="medium">Get Started</Text>
        </StyledButtonPrimary>
        <Flex
          mt="l"
          maxWidth="600px"
          width={{ mobile: 'auto', tablet: '100%' }}
          justifyContent="space-between"
          flexDirection={{ mobile: 'column', tablet: 'row' }}
        >
          {FEATURE_ITEMS.map((item) => (
            <Flex mb="m" pb="s" key={item} alignItems="center">
              <LocalImage source={checkmarkWithCicle} width="16px" height="16px" alt={item} />
              <Text ml="s" pl="xxs" fontSize="s">
                {item}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}

GetStartedComponent.defaultProps = {}

GetStartedComponent.propTypes = {}

export default GetStartedComponent
