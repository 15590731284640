import React from 'react'
import styled from 'styled-components'
import { parseTextFromServer, getImageByFeaturedMedia } from 'helpers'
import { Text } from './Typography'
import { toHumanReadable } from '../utils/date'
import { Box } from './Layout'

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || '320px'};
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`

const BlogItem = ({
  featured_media,
  date: createdAt,
  author: { name: author },
  title,
  excerpt,
  slug,
  showAuthor = true,
  showTeaser = true,
  maxWidth,
  shortTeaser,
}) => {
  const { featuredImageProps, WrapperComp } = getImageByFeaturedMedia(featured_media)
  const StyledImg = styled(WrapperComp)`
    object-fit: contain;
    max-height: 200px;
    min-height: 150px;
    & > picture > img {
      object-fit: contain !important;
    }
  `

  let teaser = parseTextFromServer(excerpt.replace('[&hellip;]', ''))

  if (shortTeaser) {
    teaser = teaser.split('.').slice(0, 1)
  }

  return (
    <StyledLink href={`/insights/${slug}/`} maxWidth={maxWidth}>
      <StyledImg {...featuredImageProps} />

      <Text opacity={0.8} mt="m" fontSize="xxs" color="secondaryText">
        {toHumanReadable(createdAt).toUpperCase()} {showAuthor && `- ${author.toUpperCase()}`}
      </Text>
      <Box>
        <Text fontSize="l">{parseTextFromServer(title)}</Text>
      </Box>

      {showTeaser && (
        <Text opacity={0.8} mt="m" color="secondaryText">
          {`${teaser}... Read more`}
        </Text>
      )}
    </StyledLink>
  )
}

export default BlogItem
