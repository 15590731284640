import React from 'react'
import styled from 'styled-components'
import { order, height } from 'styled-system'
import { Flex } from 'components/Layout'
import { H3, Text } from 'components/Typography'
import { COLOR_CONSTANTS } from 'theme'
import { toHumanReadable } from 'utils/date'
import { parseTextFromServer, getImageByFeaturedMedia } from 'helpers'

const StyledLink = styled.a`
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`

const StyledFlex = styled(Flex)`
  align-self: flex-end;
  flex-direction: column;
  z-index: 2;
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
`

const StyledText = styled(Text)`
  letter-spacing: 0.2px;
  opacity: 0.9;
`

const PostHeroSmall = ({
  featured_media,
  date: createdAt,
  author: { name: author },
  title,
  excerpt,
  isReversed,
  slug,
}) => {
  const { featuredImageProps, WrapperComp } = getImageByFeaturedMedia(featured_media)
  const StyledImage = styled(WrapperComp)`
    width: 530px;
    max-width: 90vw;
    object-fit: contain;
    pointer-events: none;
    ${order}
    ${height}
    & > picture > img {
      object-fit: contain !important;
    }
  `

  const teaser = parseTextFromServer(excerpt.replace('[&hellip;]', ''))
    .split('.')
    .slice(0, 2)
    .join('.')

  return (
    <StyledLink href={`/insights/${slug}`}>
      <Flex flexDirection={['column', 'row']}>
        <Flex order={[1, isReversed ? 2 : 1]} justifyContent="center" alignItems="center">
          <StyledImage height={['auto', '300px']} {...featuredImageProps} />
        </Flex>
        <StyledFlex
          order={[2, isReversed ? 1 : 2]}
          width={['100%', '440px']}
          ml={['0px', isReversed ? '0px' : '-30px']}
          mr={['0px', isReversed ? '-30px' : '0px']}
          px={['s', 'l']}
          py={['s', 'm']}
        >
          <StyledText fontSize="xxs" color="secondaryText">
            {toHumanReadable(createdAt).toUpperCase()} - {author.toUpperCase()}
          </StyledText>
          <H3 mt="m">{parseTextFromServer(title)}</H3>
          <Text fontSize="l" opacity={0.8} lineHeight="140%" mt="m" color="secondaryText">
            {`${teaser}... Read more`}
          </Text>
        </StyledFlex>
      </Flex>
    </StyledLink>
  )
}

export default PostHeroSmall
