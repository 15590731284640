import React from 'react'
import styled from 'styled-components'
import { H3, Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import { COLOR_CONSTANTS } from 'theme'
import { toHumanReadable } from 'utils/date'
import { parseTextFromServer, getImageByFeaturedMedia } from 'helpers'

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`

const StyledFlex = styled(Flex)`
  flex-direction: column;
  max-width: 620px;
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  z-index: 1;
`

const StyledText = styled(Text)`
  letter-spacing: 0.2px;
  opacity: 0.9;
`

const PostHero = ({ featured_media, date: createdAt, author: { name: author }, title, excerpt, slug }) => {
  const { featuredImageProps, WrapperComp } = getImageByFeaturedMedia(featured_media)
  const StyledImage = styled(WrapperComp)`
    width: 100%;
    height: auto;
  `
  return (
    <StyledLink href={`/insights/${slug}`}>
      <StyledImage {...featuredImageProps} />
      <StyledFlex p={['m', 'l']} mt={[0, '-90px']}>
        <StyledText fontSize="xxs" color="secondaryText">
          {toHumanReadable(createdAt).toUpperCase()} - {author.toUpperCase()}
        </StyledText>
        <H3 my="m">{parseTextFromServer(title)}</H3>
      </StyledFlex>
    </StyledLink>
  )
}

export default PostHero
