import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import App from 'App'
import { COLOR_CONSTANTS, space, radius, colors } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import NewsletterSubScription from 'components/NewsletterSubScription'
import BlogItem from 'components/BlogItem'
import Footer from 'components/Footer'
import PostHero from 'components/PostHero'
import PostHeroSmall from 'components/PostHeroSmall'
import PaginationBadge from 'components/PaginationBadge'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import ExternalLink from 'components/Link'
import { parseTextFromServer, getImageByFeaturedMedia } from 'helpers'
import chevronRight from 'static/svg/chevron_right.svg'
import { toHumanReadable } from '../utils/date'
import GetStartedComponent from '../components/GetStartedComponent'

const Container = styled.div`
  margin: ${space.l} auto;
  max-width: 910px;
  padding-left: ${space.m};
  padding-right: ${space.m};
`

const StyledLink = styled.a`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || '320px'};
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`

const StyledCategories = styled.a`
  position: relative;
  max-width: 280px;
  width: 100%;
  min-height: 60px;
  box-shadow: 0px 4px 6px rgba(39, 40, 49, 0.1);
  background-color: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  display: flex;
  align-items: center;
  padding: 0 ${space.m};
  &:hover {
    box-shadow: rgba(39, 40, 49, 0.1) 0px 4px 20px;
  }
`

const ButtonPag = styled('button')`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  outline: none;
  background: ${colors.primary};
  border: solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${colors.white};
  color: ${COLOR_CONSTANTS.SALUTE};
  border-width: 1px;
  padding: 0 ${space.s};
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color:${COLOR_CONSTANTS.SOLITUDE}
    pointer-events: none;
  `}
`

const BlogImage = ({ featured_media }) => {
  const { featuredImageProps, WrapperComp } = getImageByFeaturedMedia(featured_media)
  const StyledImg = styled(WrapperComp)`
    object-fit: contain;
    max-height: 200px;
    width: 100%;
    & > picture > img {
      object-fit: contain !important;
    }
  `
  return <StyledImg {...featuredImageProps} />
}

const BlogList = ({
  pageContext: { numPages, currentPage, categories, pagePath, name, description = '' },
  data: {
    allWordpressPost: { edges },
  },
}) => {
  const articles = edges.map(({ node }) => node)
  const isFirstPage = currentPage === 1
  const chunkOfArticles = isFirstPage ? articles.slice(3, 9) : articles
  const chunkOfArticlesFirstRow = chunkOfArticles.slice(0, 2)
  const chunkOfArticlesElseDesktopMobile = chunkOfArticles.slice(2)
  let temp = []
  const chunkOfArticlesElseDesktop = []
  let k = 0
  for (let i = 0; i < chunkOfArticlesElseDesktopMobile.length; i++) {
    k++
    temp.push(chunkOfArticlesElseDesktopMobile[i])
    if (k === 3) {
      chunkOfArticlesElseDesktop.push(temp)
      temp = []
      k = 0
    }
    if (!chunkOfArticlesElseDesktopMobile[i + 1] && temp.length) {
      chunkOfArticlesElseDesktop.push(temp)
    }
  }
  const featuredArticles = articles.slice(0, 3)
  const firstArticle = articles[0]
  const secondArticle = articles[1]
  const thirdArticle = articles[2]
  const INSIGHTS_DESCRIPTION = `Agency Vista Insights is a social media blog for business with a focus on bringing you the latest social media tips, strategies and best practices`
  const strippedDescription = description && description.replace(/(<([^>]+)>)/gi, '')
  return (
    <App>
      <SEO
        title={`Insights | ${name || ''}`}
        path={`${pagePath}${isFirstPage ? '' : `/${currentPage}`}`}
        description={strippedDescription || INSIGHTS_DESCRIPTION}
      />
      <Container>
        {isFirstPage && (
          <React.Fragment>
            <Flex flexDirection="column" mb="l">
              <H3>{name}</H3>
              {description && (
                <Text mt="s" color="secondaryText" fontSize="m" dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </Flex>
            <PostHero {...firstArticle} />
            {secondArticle && (
              <Flex mt="l" flexDirection="column" alignItems="center">
                <PostHeroSmall {...secondArticle} />
                {thirdArticle && (
                  <Box mt="xl">
                    <PostHeroSmall isReversed {...thirdArticle} />
                  </Box>
                )}
              </Flex>
            )}
          </React.Fragment>
        )}
        {chunkOfArticlesFirstRow.length > 0 && (
          <Flex mt="xl" justifyContent="center" flexDirection="column">
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l" px="s">
              {/* showing for desktop part with subscription element */}
              <Box gridColumn="1/3" display={{ mobile: 'none', desktop: 'block' }}>
                <Flex flexDirection="column">
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="l">
                    {chunkOfArticlesFirstRow.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <BlogImage {...article} />
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="l">
                    {chunkOfArticlesFirstRow.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text opacity={0.8} mt="m" fontSize="xxs" color="secondaryText">
                          {toHumanReadable(article.date).toUpperCase()} {`- ${article.author.name.toUpperCase()}`}
                        </Text>
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="l">
                    {chunkOfArticlesFirstRow.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text fontSize="l">{parseTextFromServer(article.title)}</Text>
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="l">
                    {chunkOfArticlesFirstRow.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text opacity={0.8} color="secondaryText">
                          {`${parseTextFromServer(article.excerpt.replace('[&hellip;]', ''))
                            .split('.')
                            .slice(0, 1)}... Read more`}
                        </Text>
                      </StyledLink>
                    ))}
                  </Grid>
                </Flex>
              </Box>
              {/* showing for mobiles part with subscription element */}
              {chunkOfArticlesFirstRow.map((article, index) => (
                <Box key={index} px={['s', 0]} display={{ mobile: 'block', desktop: 'none' }}>
                  <BlogItem key={article.id} {...article} maxWidth="100%" shortTeaser />
                </Box>
              ))}
              <Box mx={`-${space.m}`}>
                <NewsletterSubScription type="insights" inputFullWidth />
              </Box>
            </Grid>
            {/* showing for desktop part with other articles */}
            {chunkOfArticlesElseDesktop.map((item, index) => {
              return (
                <Flex key={index} flexDirection="column" mb="m" display={{ mobile: 'none', desktop: 'block' }}>
                  <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
                    {item.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <BlogImage {...article} />
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
                    {item.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text opacity={0.8} mt="m" fontSize="xxs" color="secondaryText">
                          {toHumanReadable(article.date).toUpperCase()} {`- ${article.author.name.toUpperCase()}`}
                        </Text>
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
                    {item.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text fontSize="l">{parseTextFromServer(article.title)}</Text>
                      </StyledLink>
                    ))}
                  </Grid>
                  <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
                    {item.map((article, index) => (
                      <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                        <Text opacity={0.8} color="secondaryText">
                          {`${parseTextFromServer(article.excerpt.replace('[&hellip;]', ''))
                            .split('.')
                            .slice(0, 1)}... Read more`}
                        </Text>
                      </StyledLink>
                    ))}
                  </Grid>
                </Flex>
              )
            })}
            {/* showing for mobiles part with other articles */}
            <Grid
              mt="l"
              gridTemplateColumns={['1fr', 'repeat(3, 1fr)']}
              gridGap="l"
              px="s"
              display={{ mobile: 'block', desktop: 'none' }}
            >
              {chunkOfArticlesElseDesktopMobile.map((article, index) => (
                <Box mb="l" key={index} px={['s', 0]}>
                  <BlogItem key={article.id} {...article} maxWidth="100%" shortTeaser />
                </Box>
              ))}
            </Grid>
          </Flex>
        )}
        <Flex mt="xl" justifyContent="space-between">
          <Box alignSelf="flex-start" minWidth="45px">
            {currentPage > 1 && (
              <ButtonPag as={ExternalLink} href={`/insights/${currentPage - 1 === 1 ? '' : currentPage - 1}/`}>
                Previous
              </ButtonPag>
            )}
          </Box>
          <PaginationBadge isActive>{currentPage}</PaginationBadge>
          <Box alignSelf="flex-end" minWidth="45px">
            {currentPage < numPages && (
              <ButtonPag as={ExternalLink} href={`/insights/${currentPage + 1}/`}>
                Next
              </ButtonPag>
            )}
          </Box>
        </Flex>
        <Box mt="xxl">
          <GetStartedComponent />
        </Box>
        <Flex mt="l" justifyContent="center">
          {/* showing for desktop part with featured articles */}
          <Flex mt="m" flexDirection="column" display={{ mobile: 'none', desktop: 'block' }}>
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
              {featuredArticles.map((article, index) => (
                <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                  <BlogImage {...article} />
                </StyledLink>
              ))}
            </Grid>
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
              {featuredArticles.map((article, index) => (
                <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                  <Text opacity={0.8} mt="m" fontSize="xxs" color="secondaryText">
                    {toHumanReadable(article.date).toUpperCase()} {`- ${article.author.name.toUpperCase()}`}
                  </Text>
                </StyledLink>
              ))}
            </Grid>
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
              {featuredArticles.map((article, index) => (
                <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                  <Text fontSize="l">{parseTextFromServer(article.title)}</Text>
                </StyledLink>
              ))}
            </Grid>
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
              {featuredArticles.map((article, index) => (
                <StyledLink href={`/insights/${article.slug}/`} flex="1" key={index}>
                  <Text opacity={0.8} color="secondaryText">
                    {`${parseTextFromServer(article.excerpt.replace('[&hellip;]', ''))
                      .split('.')
                      .slice(0, 1)}... Read more`}
                  </Text>
                </StyledLink>
              ))}
            </Grid>
          </Flex>
          {/* showing for mobiles part with featured articles */}
          <Grid
            gridTemplateColumns={['1fr', 'repeat(3, 1fr)']}
            gridGap="l"
            display={{ mobile: 'block', desktop: 'none' }}
          >
            {featuredArticles.map((article) => (
              <Box mt="m" mb="s" key={article.id}>
                <BlogItem {...article} maxWidth="100%" />
              </Box>
            ))}
          </Grid>
        </Flex>
        <Flex mt="l" flexDirection="column">
          <H3 mt="xl" mb="l" textAlign="center">
            Explore more topics
          </H3>
          <Grid
            gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
            gridGap="m"
            justifyItems="center"
          >
            {categories.map(({ pagePath, name }) => (
              <StyledCategories href={pagePath} key={pagePath}>
                <Text fontSize="s" color="primaryText" pr="l">
                  {name}
                </Text>
                <Flex position="absolute" height="14px" width="20px" right={space.s}>
                  <LocalImage source={chevronRight} />
                </Flex>
              </StyledCategories>
            ))}
          </Grid>
        </Flex>
      </Container>
      <Box mt="xl">
        <Footer />
      </Box>
    </App>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $filter: [String!]) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { categories: { elemMatch: { slug: { in: $filter } } } }
    ) {
      edges {
        node {
          id
          path
          title
          excerpt
          date
          modified
          slug
          author {
            name
          }
          categories {
            slug
            name
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
