import React from 'react'
import styled from 'styled-components'
import { space, colors, radius, COLOR_CONSTANTS } from 'theme'
import { Text } from 'components/Typography'

const Badge = styled.button`
  justify-content: center;
  min-width: 30px;
  height: 30px;
  outline: none;
  background: none;
  border: solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.s};
  padding: 0 ${space.s};
  ${({ isActive }) =>
    isActive
      ? `
    background-color: ${colors.primary};
    color: ${colors.white};
    border-width: 0;
  `
      : `
    background-color: ${colors.white};
    color: ${COLOR_CONSTANTS.SALUTE};
    border-width: 1px;
  `}
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
  `}
`

const PaginationBadge = ({ children, isActive, onClick }) => (
  <Badge isActive={isActive} onClick={onClick}>
    <Text>{children}</Text>
  </Badge>
)

PaginationBadge.defaultProps = {
  isActive: false,
}

export default PaginationBadge
