import React, { useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import SyncLoader from 'react-spinners/SyncLoader'
import { space, COLOR_CONSTANTS } from 'theme'
import { Box, Flex } from './Layout'
import { Text, H5 } from './Typography'
import Input from './Input'
import chatbubbles from '../static/images/chatbubbles.png'
import arrowRight from '../static/svg/arrow_right.svg'

const StyledFlex = styled(Flex)`
  padding-top: ${space.xl};
  padding-bottom: ${space.xl};
  padding-left: ${space.m};
  padding-right: ${space.m};
  border-top: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  ${({ hasBottomBorder }) => hasBottomBorder && `border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};`}
`

const StyledImg = styled.img`
  cursor: pointer;
`

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please use a valid email address')
    .required('Please use a valid email address'),
})

const NewsletterSubScription = ({ hasBottomBorder, type, inputFullWidth }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmitForm = async (values) => {
    const { email } = values
    try {
      setIsSubmitting(true)
      await fetch(`${process.env.GATSBY_API_URL}/subscribe?email=${email}&type=${type}`)
      navigate('/thankyou')
    } catch (error) {
      navigate('/500')
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <StyledFlex flexDirection="column" alignItems="center" textAlign="center" hasBottomBorder={hasBottomBorder}>
      <img width="79px" src={chatbubbles} alt="Let's keep in touch" />
      <H5 mt="l">Let's Keep in Touch</H5>
      <Text my="s" color="secondaryText" fontSize="s">
        Subscribe to keep up with fresh news and exciting updates. We promise not to spam you!
      </Text>
      <Box mt="m" maxWidth="300px" width="100%">
        <Formik values={{ email: '' }} validationSchema={EmailValidationSchema} onSubmit={handleSubmitForm}>
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <Input
                width={inputFullWidth ? '100%' : '300px'}
                placeholder="email@example.com"
                value={values.email}
                onChange={handleChange('email')}
                error={errors.email && touched.email && errors.email}
                pr="l"
                iconComp={
                  isSubmitting ? (
                    <SyncLoader size="3" color="black" />
                  ) : (
                      <StyledImg onClick={handleSubmit} src={arrowRight} />
                    )
                }
              />
            </form>
          )}
        </Formik>
      </Box>
    </StyledFlex>
  )
}

NewsletterSubScription.defaultProps = {
  hasBottomBorder: true,
  type: 'insights',
}

NewsletterSubScription.propTypes = {
  hasBottomBorder: PropTypes.bool,
  type: PropTypes.string,
}

export default NewsletterSubScription
